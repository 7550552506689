<template>
  <div>
    <h1> 503 </h1>
    <h2> Service Unavailable </h2> <br>
    <p> Our website is temporarily unavailable due to maintenance. </p>
  </div>
</template>

<script>
export default {
  name: 'Error403',
  data () {
    return {

    }
  }
}

</script>

<style scoped>

div{
  text-align: center;
}
h1{
  font-size: 10rem;
}
P {
  margin-bottom: 0;
}
</style>
